import { createContext, useCallback, useContext, useState } from 'react'

export const FeatureFlagContext = createContext({})
export const FeatureFlagProvider = ({ children }) => {
  const [variations, _setVariations] = useState({})

  const setVariation = useCallback(
    (experimentSection, variation) => {
      _setVariations((state) => ({ ...state, [experimentSection]: variation }))
    },
    [_setVariations]
  )

  const setVariations = useCallback(
    (variations) => {
      _setVariations((state) => ({
        ...state,
        ...variations.reduce(
          (result, { experimentSection, variation }) => ({
            ...result,
            [experimentSection]: variation,
          }),
          {}
        ),
      }))
    },
    [_setVariations]
  )

  const getExperimentVariation = useCallback(
    (experimentSection, defaultValue = 'control') =>
      variations[experimentSection] || defaultValue,
    [variations]
  )

  return (
    <FeatureFlagContext.Provider
      value={{
        setVariation,
        setVariations,
        getExperimentVariation,
        variations,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}
export const useFeatureFlags = () => useContext(FeatureFlagContext)

export const ABTestingContext = createContext({})
export const useABTestingVersion = () => useContext(ABTestingContext)
