export const ABExperiments = {
  Homepage: {
    experiment_id: 'fdr-homepage-slider-cta-test',
    experiment_name: 'FDR: Homepage slider cta test',
  },
  HomepageRedesign: {
    experiment_id: 'homepage-redesign-multi-variate-test',
    experiment_name: 'FDR: Homepage Redesign Multi-variate Test',
  },
  article: {
    experiment_id: 'fdr-article-slider-banner-test',
    experiment_name: 'FDR: Article slider banner test',
  },
  dataVisualization: {
    experiment_id: 'fdr-home-data-visualization-test',
    experiment_name: 'FDR: Home page data visualization test',
  },
  floatingHeaderFooter: {
    experiment_id: 'fdr-floating-header-footer',
    experiment_name: 'FDR: Floating Header/Footer',
  },
  homeHeroCta: {
    experiment_id: 'fdr-homepage-hero-cta',
    experiment_name: 'FDR: Home page hero cta',
  },
  clientLogin: {
    experiment_id: 'fdr-client-login-cta-on-mobile-top-nav',
    experiment_name: 'FDR: Client login cta on mobile top nav',
  },
}

export const experimentSectionByVariation = {
  'fdr-client-login-cta-on-mobile-top-nav': 'clientLogin',
  'fdr-homepage-hero-cta': 'homeHeroCta',
  'homepage-redesign-multi-variate-test': 'HomepageRedesign',
  'fdr-home-data-visualization-test': 'dataVisualization',
  'fdr-floating-header-footer': 'floatingHeaderFooter',
}

export const ABExperimentVariations = {
  'fdr-article-slider-banner-test': {
    default: {
      name: 'control',
      label: 'Control',
    },
    'test-cell': {
      name: 'test-cell',
      label: 'Test cell',
    },
  },
  'fdr-homepage-slider-cta-test': {
    default: {
      name: 'control',
      label: 'control',
    },
    tp_below_dc: {
      name: 'tp_below_dc',
      label: 'tp_below_dc',
    },
  },
  'homepage-redesign-multi-variate-test': {
    control: {
      name: 'control',
      label: 'Control',
    },
    'control-ny': {
      name: 'control_ny',
      label: 'Control NY',
    },
    'variant-2': {
      name: 'variant-2',
      label: 'Variant 2',
    },
    'variant-3': {
      name: 'variant-3',
      label: 'Variant 3',
    },
    'variant-4': {
      name: 'variant-4',
      label: 'Variant 4',
    },
    'variant-5': {
      name: 'variant-5',
      label: 'Variant 5',
    },
    'variant-6': {
      name: 'variant-6',
      label: 'Variant 6',
    },
  },
  'fdr-home-data-visualization-test': {
    default: {
      name: 'control_with_data_visualization',
      label: 'Control with data visualization',
    },
    control: {
      name: 'control',
      label: 'Control',
    },
    control_with_data_visualization: {
      name: 'control_with_data_visualization',
      label: 'Control with data visualization',
    },
    control_with_bar_chart: {
      name: 'control_with_bar_chart',
      label: 'Control with bar chart',
    },
    hide_data_visualization: {
      name: 'hide_data_visualization',
      label: 'Hide data visualization',
    },
  },
  'fdr-floating-header-footer': {
    control: {
      name: 'control',
      label: 'Control',
    },
    floating_menu: {
      name: 'floating_menu',
      label: 'Floating Menu',
    },
  },
  'fdr-client-login-cta-on-mobile-top-nav': {
    default: {
      name: 'control',
      label: 'Control',
    },
    client_login: {
      name: 'client_login',
      label: 'Client login',
    },
  },
  'fdr-homepage-hero-cta': {
    control: {
      name: 'control',
      label: 'Control',
    },
    floating_menu: {
      name: 'floating_menu',
      label: 'Floating Menu',
    },
    get_a_free_debt_assessment: {
      name: 'get_a_free_debt_assessment',
      label: 'Get a free debt assessment',
    },
  },
}
