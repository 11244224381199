import { parseCookies } from './utils'

export function removeExtraSpaces(str) {
  if (!str) return undefined

  return str.replace(/\s\s+/g, ' ').trim()
}

export const getAttributesFromHtml = (html) => {
  const regex = new RegExp(
    '[\\s\\r\\t\\n]*([a-z0-9\\-_]+)[\\s\\r\\t\\n]*=[\\s\\r\\t\\n]*([\'"])((?:\\\\\\2|(?!\\2).)*)\\2',
    'ig'
  )
  const attributes = {}
  let match

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(html))) {
    // eslint-disable-next-line prefer-destructuring
    attributes[match[1]] = match[3]
  }

  return attributes
}

export const getNameFromVariation = (variation) => {
  let words = variation.split('_')
  words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1)

  return words.join(' ')
}

// redesign
export function numberFormat(value, options) {
  const opts = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  }

  let val = ''
  if (!Number.isNaN(Number(value))) {
    val = Intl.NumberFormat('en-US', opts).format(value)
  }

  return val
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}

export function getCookeByKey(cookie, key) {
  return cookie
    .split(';')
    .find((item) => item.includes(key))
    ?.replace(/(.*)=/, '')
}

export function setDotsClassName(current, document, name) {
  const itemsCount =
    document.getElementsByClassName(name)[0].lastChild.lastChild.childNodes
      .length
  if (Math.round(current) === itemsCount - 1) {
    document
      .getElementsByClassName(name)[0]
      .lastChild.lastChild.lastChild.classList.add('slick-active')
  } else {
    document
      .getElementsByClassName(name)[0]
      .lastChild.lastChild.lastChild.classList.remove('slick-active')
  }
}

export function getTitleFromPath(path) {
  const segments = path.split('/')

  const title =
    (segments.includes('l') && segments.includes('page')) ||
    (segments.includes('blog') && segments.includes('page')) ||
    (segments.includes('author') && segments.includes('page'))
      ? segments[segments.length - 4]
          .replaceAll('-', ' ')
          .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()) +
        ' ' +
        'Page-' +
        segments[segments.length - 2]
      : path
          .split('?')[0]
          ?.match(/\/([^\/]+)\/?$/)[1]
          .replaceAll('-', ' ')
          .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase())

  const isHomePage = new RegExp(
    '(localhost:7000|(freedomdebtrelief|(fdr.(dev|stg|sbx|prd).fdrgcp)).com)',
    'i'
  )

  return isHomePage.test(title) ? 'Home' : title
}

export function getScrollPercentage() {
  const footer = document.getElementsByTagName('footer')
  let windowHeight =
    window.innerHeight ||
    (document.documentElement || document.body).clientHeight
  let docHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  )
  let trackLength = docHeight - windowHeight - footer[0].offsetHeight
  const scrollPrc = Math.floor((window.pageYOffset / trackLength) * 100)

  return scrollPrc > 100 || scrollPrc < 0 ? 100 : scrollPrc
}

export function isInternalForTealium(url) {
  const internalPathRegExp = new RegExp(
    '^(?:https?:\\/\\/)?((((?:www\\.)?(freedomdebtrelief|(fdr\\.(dev|stg|sbx|prd).fdrgcp)))\\.com)|(localhost:\\d+))',
    'i'
  )

  return url?.startsWith('/') || internalPathRegExp.test(url)
}

export function toKebabCase(string) {
  return string
    ?.toLowerCase()
    .replace(/\W+/gm, ' ')
    .trim()
    .replace(/\s+/gm, '-')
}

export function getElemFromContent(content, element) {
  return content ? content.filter((item) => item.nodeType === element) : []
}

export const addHyperlinkTitle = (content, title) => {
  return content?.map((c) =>
    c.content?.map((e) => {
      if (e.nodeType === 'hyperlink') {
        e.data.title = title
      } else if (e.nodeType === 'list-item') {
        return addHyperlinkTitle(e.content, title)
      }

      return e
    })
  )
}

export function getSHAValue(inputStr) {
  const crypto = require('crypto')

  return crypto.createHash('sha256').update(inputStr).digest('hex')
}

export function generateElementId(value) {
  return value
    ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    .replaceAll(' ', '')
}

export const toCommas = (num) => {
  if (typeof num !== 'number') {
    return num
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getCookies = () => {
  return parseCookies(global.document?.cookie)
}

export const setCookie = (key, value, options = {}) => {
  let expiration = ''
  let path = options.path || '/'

  if (options.expiration) {
    const now = new Date(Date.now() + options.expiration * 1000).toUTCString()
    expiration = ` ${now};`
  }

  document.cookie = `${key}=${encodeURIComponent(
    value
  )};${expiration} path=${path};`
}
