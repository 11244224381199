import React from 'react'
import PropTypes, { elementType } from 'prop-types'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import Script from 'next/script'
import '../styles/app.scss'
import SEO from '../src/common/constants/nextSEO'
import { AnalyticsProvider } from '../src/common/analytics'
import { FeatureFlagProvider } from 'src/common/ab-testing/context'
import { LeadIdProvider } from '../src/common/lead-id'
import { useRouter } from 'next/router'
import localFont from 'next/font/local'
import { parseServerCookies } from 'src/common/serverUtils'

const ultramarine = localFont({
  src: [
    {
      path: '../public/next-assets/fonts/fonts_Ultramarine_WOFF_Ultramarine-Regular.woff2',
      weight: '400',
    },
    {
      path: '../public/next-assets/fonts/fonts_Ultramarine_WOFF_Ultramarine-Medium.woff2',
      weight: '500',
    },
    {
      path: '../public/next-assets/fonts/fonts_Ultramarine_WOFF_Ultramarine-Bold.woff2',
      weight: '700',
    },
  ],
  style: ['normal', 'italic'],
  preload: true,
  variable: '--font-ultramarine',
  display: 'swap',
  // adjustFontFallback: false,
})

function App({
  Component,
  pageProps: {
    csrfToken,
    recaptchaKey,
    dashboardAuthHost,
    dashboardRedirectHost,
    cookie,
    ...pageProps
  },
}) {
  const analyticsProps = {
    event_origin: 'client',
    page_name: 'FDR Home',
    ...pageProps,
  }

  const { query } = useRouter() //for testing
  const nonce = process.env.nonce
  const hideForTest = query?.hasOwnProperty('performance')
    ? query.performance
    : null //for testing should be removed

  const isCrawler = pageProps.isCrawler || false

  return (
    <div className={`${ultramarine.variable} font-ultramarine`}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <DefaultSeo {...SEO} />
      <Script id="env-init" nonce={nonce}>
        {`window.env=${JSON.stringify({
          csrfToken,
          recaptchaKey,
          dashboardAuthHost,
          dashboardRedirectHost,
        })}`}
      </Script>
      <LeadIdProvider leadIdFromServerCookies={cookie?.leadId}>
        <FeatureFlagProvider>
          <AnalyticsProvider
            isCrawler={isCrawler}
            pageProps={analyticsProps}
            urlParams={hideForTest}
          >
            <Component {...pageProps} />
          </AnalyticsProvider>
        </FeatureFlagProvider>
      </LeadIdProvider>
      {recaptchaKey ? (
        <Script
          id="recaptcha"
          src={`https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`}
          nonce={nonce}
          defer={true}
        />
      ) : null}
    </div>
  )
}

App.getInitialProps = async ({ ctx }) => {
  const cookie = parseServerCookies(ctx)

  return {
    pageProps: {
      cookie,
    },
  }
}

App.defaultProps = {
  pageProps: {},
  router: {},
}
App.propTypes = {
  Component: elementType.isRequired,
  pageProps: PropTypes.object,
  router: PropTypes.object,
}

export default App
